import React, { useState } from "react";
import { motion } from "framer-motion";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { steps } from "./stepsData";

const getYouTubeUrl = (time) => {
  return `https://www.youtube.com/watch?v=8TAyNmB7FQ0&t=${time}`;
};

const App = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [copiedStep, setCopiedStep] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Filter the steps to only include those with titles
  const stepsWithTitle = steps.filter(step => step.title);

  // Handle navigating to the next step
  const handleNextStep = () => {
    if (currentStep < stepsWithTitle.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  // Handle navigating to the previous step
  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Handle Copy Code functionality
  const handleCopy = (code, index) => {
    navigator.clipboard.writeText(code);
    setCopiedStep(index);
    setTimeout(() => {
      setCopiedStep(null);
    }, 2000);
  };

  // Split multiline description into bullet points and remove empty lines
  const formatDescription = (description) => {
    return description
      .split("\n")
      .filter((line) => line.trim() !== "")
      .map((line, index) => (
        <li key={index} className="mb-2">
          {line}
        </li>
      ));
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      {/* Fixed progress bar at the top */}
      <div className="fixed top-0 left-0 w-full shadow-md bg-black z-50">
        <div className="flex justify-center items-center py-4">
        <div
  key={currentStep} // Use currentStep as a key to reset the animation
  className="text-base sm:text-lg lg:text-xl font-extrabold animate-typewriter tracking-tighter font-mono text-center px-2 text-white break-words w-full sm:w-auto"
>


            {stepsWithTitle[currentStep].title}
          </div>

          {/* Hamburger Icon to toggle sidebar */}
          <div
            className="absolute top-4 right-4 cursor-pointer"
            onClick={toggleSidebar}
          >
            <div className="space-y-2">
              <div className="w-8 h-0.5 bg-white"></div>
              <div className="w-8 h-0.5 bg-white"></div>
              <div className="w-8 h-0.5 bg-white"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed right-0 top-0 w-64 bg-gray-800 text-white h-full z-40 transform ${
          sidebarOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out`}
      >
        <div className="p-6 h-full flex flex-col">
          <h3 className="text-2xl font-semibold mb-4">Steps</h3>
          {/* Scrollable list */}
          <ul className="flex-grow sidebar-scrollable font-mono">
            {stepsWithTitle.map((step, filteredIndex) => (
              <li
                key={filteredIndex}
                className={`cursor-pointer mb-2 py-2 px-4 rounded-lg transition-all duration-300 text-sm tracking-wide ${
                  currentStep === filteredIndex
                    ? "bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 text-white shadow-lg"
                    : "bg-gray-600 text-gray-200 hover:bg-gradient-to-r hover:from-blue-500 hover:via-purple-600 hover:to-pink-600 hover:text-white"
                }`}
                onClick={() => {
                  setCurrentStep(filteredIndex); // Navigate to the step
                  setSidebarOpen(false); // Collapse the sidebar
                }}
              >
                {step.title}
              </li>
            ))}
          </ul>

          {/* Text at the bottom for scroll instruction */}
          <div className="text-center text-sm text-gray-400 mt-2">
            Scroll to see more steps
          </div>
        </div>
      </div>

      {/* Step content */}
      <div className="p-2 lg:w-3/4 mx-auto pt-32 tracking-tighter font-mono">

        

      <motion.h1
  className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl p-4 font-semibold text-center mb-6 bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 text-transparent bg-clip-text rgb-border-shadow typewriter"
  initial={{ opacity: 0, scale: 0.9, rotate: -5 }}
  animate={{ opacity: 1, scale: 1, rotate: 0 }}
  transition={{ duration: 1.5 }}
>

          Create 'NotifyMe' App: Step-by-Step
        </motion.h1>
        {/* Watch this step on YouTube button - Moved above the step box, centered */}
        <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
    <a
      href={getYouTubeUrl(stepsWithTitle[currentStep].youtubeTime)}
      target="_blank"
      rel="noopener noreferrer"
      className="py-2 px-4 rounded-lg text-sm font-medium transition-all duration-300 bg-red-500 text-white hover:bg-red-600"
    >
      Watch this step on YouTube
    </a>
</div>


        {/* Render only the current step */}
        <motion.div
          key={currentStep}
          id={`step-${currentStep}`} // Added id for smooth scrolling
          className={`step-container mb-12 p-8 rounded-lg shadow-lg lg:p-12 transition-shadow duration-300 hover:shadow-xl bg-gray-900 text-white`}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {/* Conditionally render title if not empty */}
          {stepsWithTitle[currentStep].title && (
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold mb-4 tracking-tighter font-mono">
  {stepsWithTitle[currentStep].title}
</h2>

          )}

          {/* Conditionally render description if not empty */}
          {stepsWithTitle[currentStep].description && (
            Array.isArray(formatDescription(stepsWithTitle[currentStep].description)) &&
            formatDescription(stepsWithTitle[currentStep].description).length > 1 ? (
              <ul className="list-disc list-inside text-base sm:text-lg text-gray-300 mb-6">
  {formatDescription(stepsWithTitle[currentStep].description)}
</ul>

            ) : (
              <p className="text-lg text-gray-500 tracking-tighter font-mono text-base mb-6">
                {stepsWithTitle[currentStep].description.trim()}
              </p>
            )
          )}

          {/* Conditionally render code block if not empty */}
          {stepsWithTitle[currentStep].code && (
            <div className="relative">
              <SyntaxHighlighter language="javascript" style={oneDark} className="text-sm">
                {stepsWithTitle[currentStep].code}
              </SyntaxHighlighter>

              {/* Copy Button inside the code box */}
              <button
                className={`py-2 px-4 rounded-lg text-sm font-medium transition-all duration-300 absolute top-2 right-2 ${
                  copiedStep === currentStep ? "bg-green-500 text-white" : "bg-gray-500 text-white hover:bg-gray-600"
                }`}
                onClick={() => handleCopy(stepsWithTitle[currentStep].code, currentStep)}
              >
                {copiedStep === currentStep ? "Copied!" : "Copy"}
              </button>
            </div>
          )}
        </motion.div>

        {/* Navigation buttons */}
        <div className="flex justify-between items-center mt-6">
          <button
            className={`py-2 px-4 rounded-lg text-sm font-medium transition-all duration-300 ${
              currentStep === 0 ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 text-white hover:bg-blue-600"
            }`}
            onClick={handlePrevStep}
            disabled={currentStep === 0}
          >
            Previous Step
          </button>

          {/* CTA Section */}
          <a
            href="https://www.youtube.com/channel/UCWJVsGYhm7K2SuIx362YziA?sub_confirmation=1"
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 py-2 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-white font-semibold text-center rounded-lg shadow-lg animate-pulse mx-4 transition-all duration-300 hover:shadow-xl"
          >
            Don't you think this YouTube Video deserves a like & subscribe from you?
          </a>

          <button
            className={`py-2 px-4 rounded-lg text-sm font-medium transition-all duration-300 ${
              currentStep === stepsWithTitle.length - 1 ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 text-white hover:bg-blue-600"
            }`}
            onClick={handleNextStep}
            disabled={currentStep === stepsWithTitle.length - 1}
          >
            Next Step
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;
