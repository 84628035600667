// src/stepsData.js
export const steps = [
    {
      title: "Step 1.1: Install Node.js and npm",
      description: "Ensure you have Node.js and npm installed on your machine. Download Node.js: https://nodejs.org/en/download/ . Verify Installation:",
      code: `node -v
npm -v`,
youtubeTime: "4m51s",
    },
    {
      title: "Step 1.2: Create a New React App",
      description: "Open your terminal and run:",
      code: `npx create-react-app notifyme-frontend`,
      youtubeTime: "5m19s",
    },
    {
      title: "Step 1.3: Navigate to the Project Directory",
      description: "enter following command:",
      code: `cd notifyme-frontend`,
      youtubeTime: "6m31s",
    },
  
    {
      title: "Step 1.4.1: Install Tailwind CSS ",
      description: "Install Tailwind and its dependencies:",
      code: `npm install -D tailwindcss postcss autoprefixer`,
      youtubeTime: "6m48s",
    },
    {
      title: "Step 1.4.2: Initialize Tailwind CSS Configuration ",
      description: "Command:",
      code: ` npx tailwindcss init -p`,
      youtubeTime: "7m05s",
    },
    {
      title: "Step 1.5: Configure Tailwind to Remove Unused Styles in Production ",
      description: "Replace the content of tailwind.config.js with the following code:",
      code: `/** @type {import('tailwindcss').Config} */
  module.exports = {
    content: [
      "./src/**/*.{js,jsx,ts,tsx}",
    ],
    theme: {
      extend: {},
    },
    plugins: [],
  }`,
  youtubeTime: "7m20s",
    },
    {
      title: "Step 1.6: Add Tailwind Directives to CSS",
      description: "Create src/index.css (Replace existing content if the file already exists):",
      code: `@tailwind base;
@tailwind components;
@tailwind utilities;`,
youtubeTime: "7m49s",
    },
    {
      title: "Step 1.7:Import index.css in src/index.js ",
      description: "Ensure src/index.js imports the index.css file:",
      code: `import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);`,
youtubeTime: "8m10s",
    },
    {
      title: "Step 1.8: Create the Homepage ",
      description: "Replace the content of src/App.js with the following code:",
      code: `import React from 'react';

function App() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="p-8 bg-white rounded shadow-md">
        <h1 className="text-2xl font-bold mb-4 text-center">Welcome to NotifyMe</h1>
        <p className="text-gray-700 text-center">Your personal reminder app.</p>
      </div>
    </div>
  );
}

export default App;
`,
youtubeTime: "8m27s",
    },
    {
      title: "Step 1.9: Start the Development Server ",
      description: "Run:",
      code: `npm start`,
      youtubeTime: "8m43s",
    },
    {
      title: "Step 1.10: Testing the Setup ",
      description: `Verify the following:
      A new browser window/tab opens displaying the homepage.
      The page shows a centered box with the title "Welcome to NotifyMe" and the subtitle "Your personal reminder app."
      The styling should reflect Tailwind CSS styles (e.g., fonts, spacing).`,
      youtubeTime: "8m55s",
      
      
    },
    {
      title: "Step 2.1: Create a New Backend Directory",
      description: "Open your terminal and run:",
      code: `mkdir notifyme-backend
cd notifyme-backend
`,
youtubeTime: "9m44s",
    },
    {
      title: "Step 2.2: Initialize a New Node.js Project",
      description: "Run:",
      code: `npm init -y`,
      youtubeTime: "10m19s",
    },
    {
      title: "Step 2.3: Install Required Dependencies",
      description: "Install Express.js, MySQL driver, CORS, and Body-Parser:",
      code: `npm install express mysql2 cors body-parser`,
      youtubeTime: "10m39s",
    },
    {
      title: "Step 2.4: Create the Main Server File",
      description: `Create a file named index.js in the notifyme-backend directory.

Add the following code to index.js:`,
     code: `// index.js

const express = require('express');
const cors = require('cors');
const bodyParser = require('body-parser');
const mysql = require('mysql2');

const app = express();
const port = 5000;

// Middleware
app.use(cors());
app.use(bodyParser.json());

// MySQL Connection Configuration
const db = mysql.createConnection({
  host: 'localhost',
  user: 'your_mysql_username',    // Replace with your MySQL username
  password: 'your_mysql_password', // Replace with your MySQL password
  database: 'notifyme_db',
});

// Connect to MySQL
db.connect((err) => {
  if (err) {
    console.error('Error connecting to MySQL database:', err);
    return;
  }
  console.log('Connected to MySQL database');
});

// Test API Endpoint
app.get('/api/test', (req, res) => {
  res.json({ message: 'Backend is working!' });
});

// Start the server
app.listen(port, () => {
  console.log(\`Server is running on port \${port}\`);
});

`,
youtubeTime: "11m16s",

    },
    {
      title: "Step 2.5.1: Install MySQL Server (If Not Already Installed)",
      description: `Download and Install XAMPP Server on Windows PC.
      Open Xampp Server & Start Apache & MySql.
      visit http://localhost/phpmyadmin/index.php 
      Click on SQL

      `,
      youtubeTime: "12m04s",
    },
    {
      title: "Step 2.5.2: Create the Database:",
      code: `CREATE DATABASE notifyme_db;`,
      youtubeTime: "12m19s",
    },
    {
      title: "Step 2.6: Start the Backend Server",
      description: "In your terminal, ensure you're in the notifyme-backend directory and run:",
      code: `node index.js`,
      youtubeTime: "12m41s",
    },
    {
        title: "Step 2.7.1: Test the API Endpoint : Using a Web Browser",
        description: `Open your web browser and navigate to:`,
        code: `http://localhost:5000/api/test`,
        youtubeTime: "13m01s",
    },
    {
        title: "Step 2.7.2: Testing Endpoint- Using Postman",
        description: `Open Postman (or install it).
Create a GET request to:`,
        code: `http://localhost:5000/api/test`,
        youtubeTime: "13m21s",
    },
    {
        title: "Step 2.7.3: Testing Endpoint - Using cURL ",
        description: `In a new terminal window, run:`,
        code: `curl http://localhost:5000/api/test`,
        youtubeTime: "14m15s",
    },
    {
        title: "Step 2.8.1: Optional: Install Nodemon for Development",
        description: `Nodemon automatically restarts your Node.js server when file changes are detected.
        Install Nodemon Globally:`,
        code: `npm install -g nodemon`,
        youtubeTime: "14m45s",
    },
    {
        title: "Step 2.8.2: Start the Server with Nodemon: ",
        
        code: `nodemon index.js`,
        youtubeTime: "15m17s",
    },
    {
        title: "Step 3.0: Implementing User Registration and Authentication",
        description: `In this step, we'll implement user registration and login functionality. We'll:

Create a users table in the MySQL database.
Implement backend API endpoints for registration and login.
Use bcrypt to hash passwords.
Use JSON Web Tokens (JWT) for authentication.
Create registration and login pages on the frontend.
Connect the frontend forms to the backend API.`,
       
       
    },
    {
        title: "Step 3.1: Set Up the users Table in the Database",
       
    },
    {
        title: "Step 3.1.1: Create the users Table ",
        description: `Open your MySQL client (command-line or GUI like MySQL Workbench) and run the following SQL command to create a users table:`,
        code: `CREATE TABLE users (
  id INT AUTO_INCREMENT PRIMARY KEY,
  email VARCHAR(255) NOT NULL UNIQUE,
  password VARCHAR(255) NOT NULL,
  created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
);
`,
        youtubeTime: "18m01s",
    },
    {
        title: "Step 3.1.2: Verify the Table Creation ",
        description: `Run:`,
        code: `DESCRIBE users;`,
        youtubeTime: "18m44s",
    },
    {
        title: "Step 3.2: Implement Registration Endpoint on the Backend ",
    },
    {
        title: "Step 3.2.1: Install Additional Dependencies ",
        description: `In the notifyme-backend directory, install bcrypt and jsonwebtoken:`,
        code: `npm install bcrypt jsonwebtoken`,
        youtubeTime: "19m15s",
    },
    {
        title: "Step 3.2.2: Update index.js to Include the Registration Route ",
        description: `Add the following code to index.js:`,
        code: `// index.js

// ... existing code ...

const bcrypt = require('bcrypt');
const jwt = require('jsonwebtoken');

// Secret key for JWT
const JWT_SECRET = 'your_jwt_secret_key'; // Replace with a secure secret key

// User Registration Endpoint
app.post('/api/register', (req, res) => {
  const { email, password } = req.body;

  // Validate input
  if (!email || !password) {
    return res.status(400).json({ error: 'Email and password are required' });
  }

  // Check if the email already exists
  const checkEmailQuery = 'SELECT * FROM users WHERE email = ?';
  db.query(checkEmailQuery, [email], (err, results) => {
    if (err) {
      console.error('Error checking email:', err);
      return res.status(500).json({ error: 'Database error' });
    }

    if (results.length > 0) {
      return res.status(409).json({ error: 'Email already exists' });
    } else {
      // Hash the password
      bcrypt.hash(password, 10, (err, hash) => {
        if (err) {
          console.error('Error hashing password:', err);
          return res.status(500).json({ error: 'Server error' });
        }

        // Insert the new user into the database
        const insertUserQuery = 'INSERT INTO users (email, password) VALUES (?, ?)';
        db.query(insertUserQuery, [email, hash], (err, results) => {
          if (err) {
            console.error('Error inserting user:', err);
            return res.status(500).json({ error: 'Database error' });
          }

          // Generate JWT token
          const token = jwt.sign({ id: results.insertId, email }, JWT_SECRET, { expiresIn: '1h' });

          return res.status(201).json({ message: 'User registered successfully', token });
        });
      });
    }
  });
});

// ... existing code ...`,
        youtubeTime: "20m05s",
    },
    {
        title: "Step 3.2.3: Testing the Registration Endpoint",
        description: `Step 1: Start the backend server:`,
        code: `nodemon index.js`,
        youtubeTime: "20m53s",
    },
    {
       
        description: `Step 2: Use Postman or any API testing tool to send a POST request to:`,
        code: `http://localhost:5000/api/register`,
        
    },
    {
        
        description: `Request Body (JSON):`,
        code: `{
  "email": "testuser@example.com",
  "password": "password123"
}
`,
        
    },
    {
        
        description: `Expected Response:
        Success (Status Code: 201):`,
        code: `{
  "message": "User registered successfully",
  "token": "your_jwt_token_here"
}
`,
       
    },
    {
        description: `Step 3: Verify the user in the database: run`,
        code: `SELECT * FROM users WHERE email = 'testuser@example.com';
`,
       
    },
    {
        title: "Step 3.3: Implement Login Endpoint on the Backend ",
        description: `3.3.1: Add the Login Route to index.js
        Add the following code to index.js:`,
        code: `// index.js

// ... existing code ...

// User Login Endpoint
app.post('/api/login', (req, res) => {
  const { email, password } = req.body;

  // Validate input
  if (!email || !password) {
    return res.status(400).json({ error: 'Email and password are required' });
  }

  // Fetch the user from the database
  const getUserQuery = 'SELECT * FROM users WHERE email = ?';
  db.query(getUserQuery, [email], (err, results) => {
    if (err) {
      console.error('Error fetching user:', err);
      return res.status(500).json({ error: 'Database error' });
    }

    if (results.length === 0) {
      return res.status(401).json({ error: 'Invalid email or password' });
    } else {
      const user = results[0];

      // Compare passwords
      bcrypt.compare(password, user.password, (err, isMatch) => {
        if (err) {
          console.error('Error comparing passwords:', err);
          return res.status(500).json({ error: 'Server error' });
        }

        if (isMatch) {
          // Generate JWT token
          const token = jwt.sign({ id: user.id, email: user.email }, JWT_SECRET, { expiresIn: '1h' });

          return res.status(200).json({ message: 'Login successful', token });
        } else {
          return res.status(401).json({ error: 'Invalid email or password' });
        }
      });
    }
  });
});

// ... existing code ...
`,
        youtubeTime: "22m35s",
    },
    {
       
        description: `3.3.2: Testing the Login Endpoint
Step 1: Ensure the backend server is running.

Step 2: Send a POST request to:`,
        code: `http://localhost:5000/api/login`,
        youtubeTime: "22m56s",
    },
    {
        
        description: `Request Body (JSON):`,
        code: `{
  "email": "testuser@example.com",
  "password": "password123"
}
`,
       
    },
    {
        
        description: `Expected Response:

Success (Status Code: 200):`,
        code: `{
  "message": "Login successful",
  "token": "your_jwt_token_here"
}
`,
       
    },
    {
        title: "Step 3.4: Implement Registration Form on the Frontend",
    },
    {
        title: "Step 3.4.1: Install Axios",
        description: `In the notifyme-frontend directory, install Axios:`,
        code: `npm install axios`,
        youtubeTime: "23m20s",
    },
    {
        title: "Step 3.4.2: Install React Router ",
        description: `Install React Router DOM:`,
        code: `npm install react-router-dom`,
        youtubeTime: "24m00s",
    },
    {
        title: "Step 3.4.3: Update src/index.js ",
        description: `Modify src/index.js to use BrowserRouter:`,
        code: `// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
`,
        youtubeTime: "24m20s",
    },
    {
        title: "Step 3.4.4: Update App.js ",
        description: `Modify src/App.js to include routes:`,
        code: `// src/App.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './components/Home';
import Register from './components/Register';
import Login from './components/Login';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;
`,
        youtubeTime: "24m38s",
    },
    {
        title: "Step 3.4.5: Create the Home Component ",
        description: `Create a directory src/components and add Home.js:`,
        code: `// src/components/Home.js

import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="p-8 bg-white rounded shadow-md text-center">
        <h1 className="text-2xl font-bold mb-4">Welcome to NotifyMe</h1>
        <p className="text-gray-700 mb-4">Your personal reminder app.</p>
        <div className="flex justify-center space-x-4">
          <Link to="/register" className="px-4 py-2 bg-blue-500 text-white rounded">
            Register
          </Link>
          <Link to="/login" className="px-4 py-2 bg-green-500 text-white rounded">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
`,
        youtubeTime: "24m52s",
    },
    {
        title: "Step 3.4.6: Create the Register Component ",
        description: `Add Register.js:`,
        code: `// src/components/Register.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setErrorMsg('');

    try {
      const response = await axios.post('http://localhost:5000/api/register', {
        email,
        password,
      });

      if (response.data.token) {
        // Store the token if needed
        localStorage.setItem('token', response.data.token);
        // Redirect to login
        navigate('/login');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMsg(error.response.data.error);
      } else {
        setErrorMsg('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="p-8 bg-white rounded shadow-md w-full max-w-md">
        <h2 className="text-xl font-bold mb-4 text-center">Register</h2>
        {errorMsg && <p className="text-red-500 mb-4">{errorMsg}</p>}
        <form onSubmit={handleRegister}>
          <div className="mb-4">
            <label className="block text-gray-700">Email:</label>
            <input
              type="email"
              className="w-full px-3 py-2 border rounded"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Password:</label>
            <input
              type="password"
              className="w-full px-3 py-2 border rounded"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
            />
          </div>
          <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded">
            Register
          </button>
        </form>
        <p className="mt-4 text-center">
          Already have an account?{' '}
          <a href="/login" className="text-blue-500">
            Login
          </a>
        </p>
      </div>
    </div>
  );
}

export default Register;
`,
        youtubeTime: "25m22s",
    },
    {
        title: "Step 3.4.7: Create the Login Component ",
        description: `Add Login.js:`,
        code: `// src/components/Login.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMsg('');

    try {
      const response = await axios.post('http://localhost:5000/api/login', {
        email,
        password,
      });

      if (response.data.token) {
        // Store the token
        localStorage.setItem('token', response.data.token);
        // Redirect to dashboard or home
        navigate('/dashboard'); // We'll create the dashboard later
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMsg(error.response.data.error);
      } else {
        setErrorMsg('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="p-8 bg-white rounded shadow-md w-full max-w-md">
        <h2 className="text-xl font-bold mb-4 text-center">Login</h2>
        {errorMsg && <p className="text-red-500 mb-4">{errorMsg}</p>}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700">Email:</label>
            <input
              type="email"
              className="w-full px-3 py-2 border rounded"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Password:</label>
            <input
              type="password"
              className="w-full px-3 py-2 border rounded"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
            />
          </div>
          <button type="submit" className="w-full bg-green-500 text-white py-2 rounded">
            Login
          </button>
        </form>
        <p className="mt-4 text-center">
          Don't have an account?{' '}
          <a href="/register" className="text-blue-500">
            Register
          </a>
        </p>
      </div>
    </div>
  );
}

export default Login;
`,
        youtubeTime: "25m46s",
    },
    {
        title: "Step 3.5: Testing the Frontend Forms ",
    },
    {
        title: "Step 3.5.1: Start the Frontend Server ",
        description: `In the notifyme-frontend directory, run:`,
        code: `npm start`,
        youtubeTime: "26m16s",
    },
    {
        title: "Step 3.5.2: Test the Registration Form ",
        description: `Navigate to http://localhost:3000/register.
Fill in the form with a new email and password.
Submit the form.
Expected Behavior:
On success, you should be redirected to the login page.
The new user should be added to the database.`,
        
    },
    {
        title: "Step 3.5.3: Test the Login Form ",
        description: `Navigate to http://localhost:3000/login.
Enter the email and password you just registered.
Submit the form.
Expected Behavior:
On success, you should be redirected to the dashboard (we'll implement this next).
The JWT token should be stored in localStorage.`,
       
    },
    {
        title: "Step 4: Implementing the User Dashboard and Reminder Functionality ",
    },
    {
        title: "Step 4.1: Set Up the reminders Table in the Database ",
    },
    {
        title: "Step 4.1.1: Create the reminders Table ",
        description: `Open your MySQL client and run the following SQL command to create a reminders table:`,
        code: `CREATE TABLE reminders (
  id INT AUTO_INCREMENT PRIMARY KEY,
  user_id INT NOT NULL,
  title VARCHAR(255) NOT NULL,
  description TEXT,
  reminder_time DATETIME NOT NULL,
  created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
  FOREIGN KEY (user_id) REFERENCES users(id) ON DELETE CASCADE
);
`,
        youtubeTime: "29m12s",
    },
    {
        title: "Step 4.1.2: Verify the Table Creation ",
        description: `Run:`,
        code: `DESCRIBE reminders;`,
       
    },
    {
        title: "Step 4.2: Implement Backend API Endpoints for Reminders ",
    },
    {
        title: "Step 4.2.1: Implement JWT Authentication Middleware ",
        description: `First, we'll create middleware to verify JWT tokens and authenticate users.

Create a new file middleware/auth.js in your backend directory:`,
        code: `// middleware/auth.js

const jwt = require('jsonwebtoken');

// Secret key for JWT
const JWT_SECRET = 'your_jwt_secret_key'; // Use the same secret key as before

module.exports = function (req, res, next) {
  // Get the token from the request header
  const token = req.headers['authorization'];

  if (!token) {
    return res.status(401).json({ error: 'Access denied. No token provided.' });
  }

  try {
    // Remove 'Bearer ' from the token string if present
    const bearerToken = token.startsWith('Bearer ') ? token.slice(7, token.length) : token;
    // Verify token
    const decoded = jwt.verify(bearerToken, JWT_SECRET);
    // Attach user to request object
    req.user = decoded;
    next();
  } catch (err) {
    console.error('Invalid token:', err);
    res.status(400).json({ error: 'Invalid token.' });
  }
};
`,
        youtubeTime: "29m52s",
    },
    {
        title: "Step 4.2.2: Update index.js to Use the Authentication Middleware ",
        description: `At the top of your index.js, import the middleware:`,
        code: `// index.js

// ... existing code ...

const auth = require('./middleware/auth');

// ... existing code ...
`,
        youtubeTime: "30m59s",
    },
    {
        title: "Step 4.2.3: Implement the GET Reminders Endpoint ",
        description: `Add the following code to index.js:`,
        code: `// Get all reminders for the authenticated user
app.get('/api/reminders', auth, (req, res) => {
  const userId = req.user.id;

  const getRemindersQuery = 'SELECT * FROM reminders WHERE user_id = ? ORDER BY reminder_time ASC';
  db.query(getRemindersQuery, [userId], (err, results) => {
    if (err) {
      console.error('Error fetching reminders:', err);
      return res.status(500).json({ error: 'Database error' });
    }

    res.status(200).json({ reminders: results });
  });
});
`,
        youtubeTime: "32m16s",
    },
    {
        title: "Step 4.2.4: Implement the POST Reminder Endpoint ",
        
        code: `// Create a new reminder
app.post('/api/reminders', auth, (req, res) => {
  const userId = req.user.id;
  const { title, description, reminder_time } = req.body;

  // Validate input
  if (!title || !reminder_time) {
    return res.status(400).json({ error: 'Title and reminder time are required' });
  }

  const insertReminderQuery = 'INSERT INTO reminders (user_id, title, description, reminder_time) VALUES (?, ?, ?, ?)';
  db.query(insertReminderQuery, [userId, title, description, reminder_time], (err, results) => {
    if (err) {
      console.error('Error inserting reminder:', err);
      return res.status(500).json({ error: 'Database error' });
    }

    res.status(201).json({ message: 'Reminder created successfully', reminderId: results.insertId });
  });
});
`,
        youtubeTime: "32m33s",
    },
    {
        title: "Step 4.2.5: Implement the PUT Reminder Endpoint ",
        
        code: `// Update an existing reminder
app.put('/api/reminders/:id', auth, (req, res) => {
  const userId = req.user.id;
  const reminderId = req.params.id;
  const { title, description, reminder_time } = req.body;

  // Validate input
  if (!title || !reminder_time) {
    return res.status(400).json({ error: 'Title and reminder time are required' });
  }

  // Update reminder
  const updateReminderQuery = 'UPDATE reminders SET title = ?, description = ?, reminder_time = ? WHERE id = ? AND user_id = ?';
  db.query(updateReminderQuery, [title, description, reminder_time, reminderId, userId], (err, results) => {
    if (err) {
      console.error('Error updating reminder:', err);
      return res.status(500).json({ error: 'Database error' });
    }

    if (results.affectedRows === 0) {
      return res.status(404).json({ error: 'Reminder not found' });
    }

    res.status(200).json({ message: 'Reminder updated successfully' });
  });
});
`,
        youtubeTime: "32m46s",
    },
    {
        title: "Step 4.2.6: Implement the DELETE Reminder Endpoint ",
        
        code: `// Delete a reminder
app.delete('/api/reminders/:id', auth, (req, res) => {
  const userId = req.user.id;
  const reminderId = req.params.id;

  const deleteReminderQuery = 'DELETE FROM reminders WHERE id = ? AND user_id = ?';
  db.query(deleteReminderQuery, [reminderId, userId], (err, results) => {
    if (err) {
      console.error('Error deleting reminder:', err);
      return res.status(500).json({ error: 'Database error' });
    }

    if (results.affectedRows === 0) {
      return res.status(404).json({ error: 'Reminder not found' });
    }

    res.status(200).json({ message: 'Reminder deleted successfully' });
  });
});
`,
        youtubeTime: "32m58s",
    },
    {
        title: "Step 4.2.7: Testing the API Endpoints ",
        description: `Note: Use Postman or any API testing tool.

Testing Authentication
Get a valid token: Login with a user and obtain the JWT token.
Include the token in the Authorization header for each request:`,
        code: `Authorization: Bearer your_jwt_token_here`,
        youtubeTime: "33m08s",
    },
    {
      
        description: `Testing GET /api/reminders`,
        code: `Method: GET
URL: http://localhost:5000/api/reminders
Headers:⬇️
Authorization: Bearer your_jwt_token_here`,
       
    },
    {
       
        description: `Expected Response:
Status Code: 200
Body:`,
        code: `{
  "reminders": []
}
`,
       
    },
    {
        
        description: `Testing POST /api/reminders
        Request:
Method: POST
URL: http://localhost:5000/api/reminders
Headers:
Authorization: Bearer your_jwt_token_here
Content-Type: application/json
Body:`,
        code: `{
  "title": "Test Reminder",
  "description": "This is a test reminder",
  "reminder_time": "2023-10-15 15:00:00"
}
`,
       
    },
    {
        
        description: `Expected Response:
Status Code: 201
Body:`,
        code: `{
  "message": "Reminder created successfully",
  "reminderId": 1
}
`,
       
    },
    {
        
        description: `Testing PUT /api/reminders/
Request:
Method: PUT
URL: http://localhost:5000/api/reminders/1
Headers:
Authorization: Bearer your_jwt_token_here
Content-Type: application/json
Body:`,
        code: `{
  "title": "Updated Reminder",
  "description": "This is an updated test reminder",
  "reminder_time": "2023-10-15 16:00:00"
}
`,
       
    },
    {
        
        description: `Expected Response:
Status Code: 200
Body:`,
        code: `{
  "message": "Reminder updated successfully"
}
`,
       
    },
    {
        
        description: `Testing DELETE /api/reminders/
Request:
Method: DELETE
URL: http://localhost:5000/api/reminders/1
Headers:
Authorization: Bearer your_jwt_token_here
Expected Response:
Status Code: 200
Body:`,
        code: `{
  "message": "Reminder deleted successfully"
}
`,
       
    },
    {
        title: "Step 4.3: Implement the Dashboard and Reminder Components on the Frontend ",
      
    },
    {
        title: "Step 4.3.1: Update App.js with New Routes ",
        description: `Modify src/App.js:`,
        code: `// src/App.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './components/Home';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import AddReminder from './components/AddReminder';
import EditReminder from './components/EditReminder';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/add-reminder" element={<AddReminder />} />
      <Route path="/edit-reminder/:id" element={<EditReminder />} />
    </Routes>
  );
}

export default App;
`,
        youtubeTime: "38m54s",
    },
    {
        title: "Step 4.3.2: Create a Utility Function for Authentication ",
        description: `Create a new file src/utils/auth.js:`,
        code: `// src/utils/auth.js

export function getToken() {
  return localStorage.getItem('token');
}

export function isLoggedIn() {
  return !!localStorage.getItem('token');
}

export function logout() {
  localStorage.removeItem('token');
}
`,
        youtubeTime: "39m31s",
    },
    {
        title: "Step 4.3.3: Create the Dashboard Component ",
        description: `Create src/components/Dashboard.js:`,
        code: `
// src/components/Dashboard.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { getToken, logout } from '../utils/auth';

function Dashboard() {
  const [reminders, setReminders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!getToken()) {
      navigate('/login');
    } else {
      fetchReminders();
    }
    // eslint-disable-next-line
  }, []);

  const fetchReminders = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/reminders', {
        headers: {
          Authorization: \`Bearer \${getToken()}\`,
        },
      });
      setReminders(response.data.reminders);
    } catch (error) {
      console.error('Error fetching reminders:', error);
      if (error.response && error.response.status === 401) {
        logout();
        navigate('/login');
      }
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this reminder?')) {
      try {
        await axios.delete(\`http://localhost:5000/api/reminders/\${id}\`, {
          headers: {
            Authorization: \`Bearer \${getToken()}\`,
          },
        });
        setReminders(reminders.filter((reminder) => reminder.id !== id));
      } catch (error) {
        console.error('Error deleting reminder:', error);
      }
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow p-4 flex justify-between">
        <h1 className="text-xl font-bold">NotifyMe Dashboard</h1>
        <button onClick={handleLogout} className="text-red-500">
          Logout
        </button>
      </nav>
      <div className="container mx-auto p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Your Reminders</h2>
          <Link to="/add-reminder" className="px-4 py-2 bg-blue-500 text-white rounded">
            Add Reminder
          </Link>
        </div>
        {reminders.length === 0 ? (
          <p className="text-gray-700">You have no reminders.</p>
        ) : (
          <table className="min-w-full bg-white rounded shadow">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Title</th>
                <th className="py-2 px-4 border-b">Description</th>
                <th className="py-2 px-4 border-b">Reminder Time</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {reminders.map((reminder) => (
                <tr key={reminder.id}>
                  <td className="py-2 px-4 border-b">{reminder.title}</td>
                  <td className="py-2 px-4 border-b">{reminder.description}</td>
                  <td className="py-2 px-4 border-b">{new Date(reminder.reminder_time).toLocaleString()}</td>
                  <td className="py-2 px-4 border-b">
                    <Link to={\`/edit-reminder/\${reminder.id}\`} className="text-blue-500 mr-2">Edit</Link>
                    <button onClick={() => handleDelete(reminder.id)} className="text-red-500">Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default Dashboard;

`,
        youtubeTime: "39m59s",
    },
    {
        title: "Step 4.3.4: Create the AddReminder Component ",
        description: `Create src/components/AddReminder.js:`,
        code: `
// src/components/AddReminder.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../utils/auth';

function AddReminder() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [reminderTime, setReminderTime] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  const handleAddReminder = async (e) => {
    e.preventDefault();
    setErrorMsg('');

    try {
      await axios.post(
        'http://localhost:5000/api/reminders',
        {
          title,
          description,
          reminder_time: reminderTime,
        },
        {
          headers: {
            Authorization: \`Bearer \${getToken()}\`,
          },
        }
      );
      navigate('/dashboard');
    } catch (error) {
      console.error('Error adding reminder:', error);
      setErrorMsg('An error occurred. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="p-8 bg-white rounded shadow-md w-full max-w-md">
        <h2 className="text-xl font-bold mb-4 text-center">Add Reminder</h2>
        {errorMsg && <p className="text-red-500 mb-4">{errorMsg}</p>}
        <form onSubmit={handleAddReminder}>
          <div className="mb-4">
            <label className="block text-gray-700">Title:</label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              placeholder="Enter reminder title"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Description:</label>
            <textarea
              className="w-full px-3 py-2 border rounded"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter reminder description"
            ></textarea>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Reminder Time:</label>
            <input
              type="datetime-local"
              className="w-full px-3 py-2 border rounded"
              value={reminderTime}
              onChange={(e) => setReminderTime(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded">
            Add Reminder
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddReminder;

`,
        youtubeTime: "40m21s",
    },
    {
        title: "Step 4.3.5: Create the EditReminder Component ",
        description: `Create src/components/EditReminder.js:`,
        code: `// src/components/EditReminder.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../utils/auth';

function EditReminder() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [reminderTime, setReminderTime] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchReminder();
    // eslint-disable-next-line
  }, []);

  const fetchReminder = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/reminders', {
        headers: {
          Authorization: \`Bearer \${getToken()}\`,
        },
      });
      const reminder = response.data.reminders.find((r) => r.id === parseInt(id));
      if (reminder) {
        setTitle(reminder.title);
        setDescription(reminder.description);
        setReminderTime(reminder.reminder_time.slice(0, 16)); // Format for datetime-local input
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error fetching reminder:', error);
      navigate('/dashboard');
    }
  };

  const handleEditReminder = async (e) => {
    e.preventDefault();
    setErrorMsg('');

    try {
      await axios.put(
        \`http://localhost:5000/api/reminders/\${id}\`,
        {
          title,
          description,
          reminder_time: reminderTime,
        },
        {
          headers: {
            Authorization: \`Bearer \${getToken()}\`,
          },
        }
      );
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating reminder:', error);
      setErrorMsg('An error occurred. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="p-8 bg-white rounded shadow-md w-full max-w-md">
        <h2 className="text-xl font-bold mb-4 text-center">Edit Reminder</h2>
        {errorMsg && <p className="text-red-500 mb-4">{errorMsg}</p>}
        <form onSubmit={handleEditReminder}>
          <div className="mb-4">
            <label className="block text-gray-700">Title:</label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              placeholder="Enter reminder title"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Description:</label>
            <textarea
              className="w-full px-3 py-2 border rounded"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter reminder description"
            ></textarea>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Reminder Time:</label>
            <input
              type="datetime-local"
              className="w-full px-3 py-2 border rounded"
              value={reminderTime}
              onChange={(e) => setReminderTime(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded">
            Update Reminder
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditReminder;
`,
        youtubeTime: "40m39s",
    },
    {
        title: "Step 4.3.6: Update Navigation and Routing ",
        description: `Ensure that when a user logs in, they are redirected to the dashboard.

Modify the handleLogin function in Login.js:`,
        code: `// src/components/Login.js

// ... existing code ...

const handleLogin = async (e) => {
  // ... existing code ...

  if (response.data.token) {
    // Store the token
    localStorage.setItem('token', response.data.token);
    // Redirect to dashboard
    navigate('/dashboard');
  }

  // ... existing code ...
};

// ... existing code ...
`,
        youtubeTime: "40m56s",
    },
    {
        title: "Step 4.4: Testing the Frontend Reminder Functionality ",
        description: `Go to Next Step...`,
       
        youtubeTime: "41m22s",
    },
    {
        title: "Step 4.4.1: Start the Frontend Server ",
        description: `In the notifyme-frontend directory, run:`,
        code: `npm start`,
        youtubeTime: "41m25s",
    },
    {
        title: "Step 4.4.2: Test the Dashboard ",
        description: `Login with a valid user.
Expected Behavior:
You should be redirected to the dashboard.
If there are reminders, they should be listed.
If there are no reminders, a message should indicate that.`,
        
        youtubeTime: "41m38s",
    },
    {
        title: "Step 4.4.3: Test Adding a Reminder ",
        description: `Click on "Add Reminder".
Fill in the title, description, and reminder time.
Submit the form.
Expected Behavior:
You should be redirected back to the dashboard.
The new reminder should be listed.`,
        
        youtubeTime: "42m04s",
    },
    {
        title: "Step 4.4.4: Test Editing a Reminder ",
        description: `On the dashboard, click on "Edit" next to a reminder.
Update the details.
Submit the form.
Expected Behavior:
You should be redirected back to the dashboard.
The reminder should reflect the updated details.`,
        
        youtubeTime: "42m46s",
    },
    {
        title: "Step 4.4.5: Test Deleting a Reminder ",
        description: `On the dashboard, click on "Delete" next to a reminder.
Confirm the deletion.
Expected Behavior:
The reminder should be removed from the list.`,
        
        youtubeTime: "43m24s",
    },
    {
        title: "Step 4.4.6: Test Logout ",
        description: `Click on "Logout" in the navigation bar.
Expected Behavior:
You should be redirected to the login page.
Accessing the dashboard without logging in should redirect you to the login page.`,
       
        youtubeTime: "43m56s",
    },
    {
        title: "Step 5: Implementing the Email Notification System ",
        description: `Go to next step.. `,
       
        youtubeTime: "45m20s",
    },
    {
        title: "Step 5.1: Install and Configure Nodemailer ",
        description: `We'll use Nodemailer to send emails from our Node.js backend.`,
        
        youtubeTime: "45m42s",
    },
    {
        title: "Step 5.1.1: Install Nodemailer ",
        description: `In your notifyme-backend directory, install Nodemailer:`,
        code: `npm install nodemailer`,
        youtubeTime: "45m43s",
    },
    {
        title: "Step 5.1.2: Choose an Email Service ",
        description: `For sending emails, you can use:

SMTP of an Email Provider: Gmail, Yahoo, etc.
Transactional Email Services: SendGrid, Mailgun, etc.
Note: For development purposes, we'll use Gmail's SMTP. In production, consider using a dedicated email service to avoid limitations.`,
       
        youtubeTime: "46m12s",
    },
    {
        title: "Step 5.1.3: Set Up Gmail for SMTP ",
        description: `Important: Gmail requires special configuration to allow sending emails via SMTP.

Option 1: Use App Passwords (Recommended)
Enable 2-Step Verification on your Google account.
Generate an App Password:
Go to your Google Account settings.
Navigate to Security > App passwords.
Select Mail and your device, then generate a password.
Use the App Password in place of your normal password.
Watch this step on youtube to get better understanding`,
       
        youtubeTime: "46m21s",
    },
    {
        title: "Step 5.1.4: Create Email Configuration ",
        description: `Create a new file config/emailConfig.js in your backend directory:`,
        code: `// config/emailConfig.js

const nodemailer = require('nodemailer');

// Replace with your email credentials
const transporter = nodemailer.createTransport({
  service: 'gmail',
  auth: {
    user: 'your_email@gmail.com', // Your Gmail address
    pass: 'your_email_password_or_app_password', // Your Gmail password or app password
  },
});

module.exports = transporter;
`,
        youtubeTime: "46m59s",
    },
    {
        title: "Step 5.2: Implement Email Sending Functionality ",
        description: `Go to next step to see sub steps`,
        
        youtubeTime: "47m36s",
    },
    {
        title: "Step 5.2.1: Create a Function to Send Emails ",
        description: `Create a new file utils/emailSender.js:`,
        code: `// utils/emailSender.js

const transporter = require('../config/emailConfig');

function sendReminderEmail(toEmail, subject, message) {
  const mailOptions = {
    from: '"NotifyMe App" <your_email@gmail.com>', // Replace with your email
    to: toEmail,
    subject: subject,
    text: message,
    // You can also use 'html' for HTML formatted emails
  };

  transporter.sendMail(mailOptions, (error, info) => {
    if (error) {
      console.error('Error sending email:', error);
      // Handle the error as needed
    } else {
      console.log('Email sent:', info.response);
    }
  });
}

module.exports = sendReminderEmail;
`,
        youtubeTime: "47m40s",
    },
    {
        title: "Step 5.3: Implement the Scheduler to Send Reminders ",
        description: `Go to next step to see sub steps...`,
        
        
    },
    {
        title: "Step 5.3.1: Add a New Column to Track Sent Reminders ",
        description: `To ensure we don't send duplicate emails, we'll add a is_sent column to the reminders table.

Run the following SQL command:`,
        code: `ALTER TABLE reminders ADD COLUMN is_sent BOOLEAN DEFAULT FALSE;
`,
        youtubeTime: "48m08s",
    },
    {
        title: "Step 5.3.2: Implement the Scheduler in index.js ",
        description: `Add the following code at the bottom of index.js:`,
        code: ` // index.js

// ... existing code ...

const sendReminderEmail = require('./utils/emailSender');

// Scheduler to check for due reminders every minute
setInterval(() => {
  const now = new Date();
  const currentTime = now.toISOString().slice(0, 19).replace('T', ' ');

  const getDueRemindersQuery = \`
    SELECT r.*, u.email FROM reminders r
    INNER JOIN users u ON r.user_id = u.id
    WHERE r.reminder_time <= ? AND r.is_sent = FALSE
  \`;

  db.query(getDueRemindersQuery, [currentTime], (err, results) => {
    if (err) {
      console.error('Error fetching due reminders:', err);
      return;
    }

    if (results.length > 0) {
      results.forEach((reminder) => {
        const toEmail = reminder.email;
        const subject = \\\`Reminder: \\\${reminder.title}\\\`;
        const message = \\\`Hi,

This is a reminder for:

Title: \\\${reminder.title}
Description: \\\${reminder.description}
Time: \\\${new Date(reminder.reminder_time).toLocaleString()}

- NotifyMe App\\\`;

        // Send the email
        sendReminderEmail(toEmail, subject, message);

        // Mark the reminder as sent
        const updateReminderQuery = 'UPDATE reminders SET is_sent = TRUE WHERE id = ?';
        db.query(updateReminderQuery, [reminder.id], (err, result) => {
          if (err) {
            console.error('Error updating reminder status:', err);
          } else {
            console.log(\\\`Reminder ID \\\${reminder.id} marked as sent.\\\`);
          }
        });
      });
    }
  });
}, 60000); // Check every 60,000 milliseconds (1 minute)
`,
        youtubeTime: "48m41s",
    },
    {
        title: "Step 5.3.3: Modify Reminder Creation and Update ",
        description: `Ensure that when a reminder is created or updated, the is_sent field is appropriately set.

Update the POST Reminder Endpoint:`,
        code: ` // In the app.post('/api/reminders') endpoint

// ... existing code ...

const insertReminderQuery = \`
  INSERT INTO reminders (user_id, title, description, reminder_time, is_sent)
  VALUES (?, ?, ?, ?, FALSE)
\`;

// ... existing code ...
`,
        youtubeTime: "49m01s",
    },
    {
        title: "Step 5.3.4: Update the PUT Reminder Endpoint:",
       
        code: ` // In the app.put('/api/reminders/:id') endpoint

// ... existing code ...

const updateReminderQuery = \`
  UPDATE reminders
  SET title = ?, description = ?, reminder_time = ?, is_sent = FALSE
  WHERE id = ? AND user_id = ?
\`;

// ... existing code ...
`,
        youtubeTime: "49m41s",
    },
    {
        title: "Step 5.4: Testing the Email Notification System ",
        description: `Please go to next step to see the sub-steps...`,
       
        youtubeTime: "50m12s",
    },
    {
        title: "5.4.1: Ensure the Backend Server is Running",
        description: `Start the backend server:`,
        code: `nodemon index.js`,
        youtubeTime: "50m20s",
    },
    {
        title: "Step 5.4.2: Create a Reminder Due in the Next Few Minutes ",
        description: `Use the frontend to create a new reminder with a reminder_time set to a few minutes from the current time.
Ensure your email address is associated with the user you're logged in as.`,
        
        youtubeTime: "50m36s",
    },
    {
        title: "Step 5.4.3: Monitor the Backend Console ",
        description: `Observe the console output for any errors or confirmation messages.

You should see logs similar to:`,
        code: `Email sent: 250 2.0.0 OK ...
Reminder ID 1 marked as sent.
`,
       
    },
    {
        title: "Step 5.4.4: Check Your Email Inbox ",
        description: `Verify that you have received an email with the reminder details.
Check Spam/Junk Folders if you don't see the email in your inbox.`,
        
        
    },
    {
        title: "Step 6: Enhancing the Homepage UI with Dynamic and Animated Elements ",
        description: `Go to Next step to see the sub steps...`,
       
        youtubeTime: "52m00s",
    },
    {
        title: "Step 6.1: Install Additional Dependencies ",
        description: `To add animations, we'll use Framer Motion, a popular library for React animations.

In your notifyme-frontend directory, install Framer Motion:`,
        code: `npm install framer-motion
`,
        youtubeTime: "52m36s",
    },
    {
        title: "Step 6.2: Update the Home.js Component ",
        description: `Replace the content of src/components/Home.js with the following code:`,
        code: `// src/components/Home.js

import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function Home() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
      <motion.div
        className="p-8 bg-white rounded-xl shadow-xl text-center"
        initial={{ scale: 0 }}
        animate={{ rotate: 360, scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 260,
          damping: 20,
        }}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 text-gray-800"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
        >
          Welcome to NotifyMe
        </motion.h1>
        <motion.p
          className="text-gray-600 mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
        >
          Your personal reminder app.
        </motion.p>
        <div className="flex justify-center space-x-4">
          <Link to="/register">
            <motion.button
              className="px-6 py-2 bg-blue-600 text-white rounded-full font-semibold shadow-lg"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Register
            </motion.button>
          </Link>
          <Link to="/login">
            <motion.button
              className="px-6 py-2 bg-green-600 text-white rounded-full font-semibold shadow-lg"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Login
            </motion.button>
          </Link>
        </div>
      </motion.div>
    </div>
  );
}

export default Home;
`,
        youtubeTime: "53m01s",
    },
    {
        title: "Step 6.3: Update Register.js",
        description: `Replace the content of src/components/Register.js with the following code:`,
        code: `// src/components/Register.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setErrorMsg('');

    try {
      const response = await axios.post('http://localhost:5000/api/register', {
        email,
        password,
      });

      if (response.data.token) {
        // Store the token if needed
        localStorage.setItem('token', response.data.token);
        // Redirect to login
        navigate('/login');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMsg(error.response.data.error);
      } else {
        setErrorMsg('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-400 via-blue-500 to-purple-600">
      <motion.div
        className="p-8 bg-white rounded-xl shadow-xl w-full max-w-md"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Register</h2>
        {errorMsg && (
          <motion.p
            className="text-red-500 mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {errorMsg}
          </motion.p>
        )}
        <form onSubmit={handleRegister}>
          <div className="mb-4">
            <label className="block text-gray-700">Email:</label>
            <motion.input
              type="email"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email"
              whileFocus={{ scale: 1.02 }}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Password:</label>
            <motion.input
              type="password"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
              whileFocus={{ scale: 1.02 }}
            />
          </div>
          <motion.button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-full font-semibold shadow-md"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Register
          </motion.button>
        </form>
        <p className="mt-4 text-center text-gray-600">
          Already have an account?{' '}
          <a href="/login" className="text-blue-500 hover:underline">
            Login
          </a>
        </p>
      </motion.div>
    </div>
  );
}

export default Register;
`,
        
    },
    {
        title: "Step 6.4: Update Login.js",
        description: `Replace the content of src/components/Login.js with the following code:`,
        code: `// src/components/Login.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMsg('');

    try {
      const response = await axios.post('http://localhost:5000/api/login', {
        email,
        password,
      });

      if (response.data.token) {
        // Store the token
        localStorage.setItem('token', response.data.token);
        // Redirect to dashboard
        navigate('/dashboard');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMsg(error.response.data.error);
      } else {
        setErrorMsg('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-600 via-blue-500 to-green-400">
      <motion.div
        className="p-8 bg-white rounded-xl shadow-xl w-full max-w-md"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Login</h2>
        {errorMsg && (
          <motion.p
            className="text-red-500 mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {errorMsg}
          </motion.p>
        )}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700">Email:</label>
            <motion.input
              type="email"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email"
              whileFocus={{ scale: 1.02 }}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Password:</label>
            <motion.input
              type="password"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
              whileFocus={{ scale: 1.02 }}
            />
          </div>
          <motion.button
            type="submit"
            className="w-full bg-green-600 text-white py-2 rounded-full font-semibold shadow-md"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Login
          </motion.button>
        </form>
        <p className="mt-4 text-center text-gray-600">
          Don't have an account?{' '}
          <a href="/register" className="text-green-500 hover:underline">
            Register
          </a>
        </p>
      </motion.div>
    </div>
  );
}

export default Login;
`,
      
    },
    {
        title: "Step 6.5: Update Dashboard.js",
        description: `Replace the content of src/components/Dashboard.js with the following code:`,
        code: ` // src/components/Dashboard.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { getToken, logout } from '../utils/auth';
import { motion } from 'framer-motion';

function Dashboard() {
  const [reminders, setReminders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!getToken()) {
      navigate('/login');
    } else {
      fetchReminders();
    }
    // eslint-disable-next-line
  }, []);

  const fetchReminders = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/reminders', {
        headers: {
          Authorization: \`Bearer \${getToken()}\`,
        },
      });
      setReminders(response.data.reminders);
    } catch (error) {
      console.error('Error fetching reminders:', error);
      if (error.response && error.response.status === 401) {
        logout();
        navigate('/login');
      }
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this reminder?')) {
      try {
        await axios.delete(\`http://localhost:5000/api/reminders/\${id}\`, {
          headers: {
            Authorization: \`Bearer \${getToken()}\`,
          },
        });
        // Remove the deleted reminder from the state
        setReminders(reminders.filter((reminder) => reminder.id !== id));
      } catch (error) {
        console.error('Error deleting reminder:', error);
      }
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow p-4 flex justify-between items-center">
        <h1 className="text-xl font-bold text-gray-800">NotifyMe Dashboard</h1>
        <button
          onClick={handleLogout}
          className="text-red-500 hover:text-red-700 font-semibold"
        >
          Logout
        </button>
      </nav>
      <div className="container mx-auto p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold text-gray-800">Your Reminders</h2>
          <Link
            to="/add-reminder"
            className="px-4 py-2 bg-blue-600 text-white rounded-full font-semibold shadow-md hover:bg-blue-700 transition duration-200"
          >
            Add Reminder
          </Link>
        </div>
        {reminders.length === 0 ? (
          <motion.p
            className="text-gray-700"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            You have no reminders.
          </motion.p>
        ) : (
          <motion.table
            className="min-w-full bg-white rounded-xl shadow overflow-hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <thead className="bg-gray-200">
              <tr>
                <th className="py-3 px-4 text-left text-gray-600">Title</th>
                <th className="py-3 px-4 text-left text-gray-600">Description</th>
                <th className="py-3 px-4 text-left text-gray-600">Reminder Time</th>
                <th className="py-3 px-4 text-left text-gray-600">Actions</th>
              </tr>
            </thead>
            <tbody>
              {reminders.map((reminder, index) => (
                <motion.tr
                  key={reminder.id}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                >
                  <td className="py-3 px-4">{reminder.title}</td>
                  <td className="py-3 px-4">{reminder.description}</td>
                  <td className="py-3 px-4">
                    {new Date(reminder.reminder_time).toLocaleString()}
                  </td>
                  <td className="py-3 px-4">
                    <Link
                      to={\`/edit-reminder/\${reminder.id}\`}
                      className="text-blue-500 hover:text-blue-700 font-semibold mr-2"
                    >
                      Edit
                    </Link>
                    <button
                      onClick={() => handleDelete(reminder.id)}
                      className="text-red-500 hover:text-red-700 font-semibold"
                    >
                      Delete
                    </button>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </motion.table>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
`,
       
    },
    {
        title: "Step 6.6: Update the AddReminder Page",
        description: `Replace the content of src/components/AddReminder.js with the following code:`,
        code: ` // src/components/AddReminder.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../utils/auth';
import { motion } from 'framer-motion';

function AddReminder() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [reminderTime, setReminderTime] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  const handleAddReminder = async (e) => {
    e.preventDefault();
    setErrorMsg('');

    // No need to adjust for timezone offset; use the reminderTime as is
    const reminderTimeFormatted = reminderTime.replace('T', ' ') + ':00';

    try {
      await axios.post(
        'http://localhost:5000/api/reminders',
        {
          title,
          description,
          reminder_time: reminderTimeFormatted,
        },
        {
          headers: {
            Authorization: \`Bearer \${getToken()}\`,
          },
        }
      );
      navigate('/dashboard');
    } catch (error) {
      console.error('Error adding reminder:', error);
      setErrorMsg('An error occurred. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 flex items-center justify-center">
      <motion.div
        className="p-8 bg-white rounded-xl shadow-xl w-full max-w-md"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Add Reminder</h2>
        {errorMsg && (
          <motion.p
            className="text-red-500 mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {errorMsg}
          </motion.p>
        )}
        <form onSubmit={handleAddReminder}>
          <div className="mb-4">
            <label className="block text-gray-700">Title:</label>
            <motion.input
              type="text"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              placeholder="Enter reminder title"
              whileFocus={{ scale: 1.02 }}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Description:</label>
            <motion.textarea
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter reminder description"
              whileFocus={{ scale: 1.02 }}
            ></motion.textarea>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Reminder Time:</label>
            <motion.input
              type="datetime-local"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
              value={reminderTime}
              onChange={(e) => setReminderTime(e.target.value)}
              required
              whileFocus={{ scale: 1.02 }}
            />
          </div>
          <motion.button
            type="submit"
            className="w-full bg-purple-600 text-white py-2 rounded-full font-semibold shadow-md"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Add Reminder
          </motion.button>
        </form>
      </motion.div>
    </div>
  );
}

export default AddReminder;
`,
        
    },
    {
        title: "Step 6.7: Update EditReminder.js",
        description: `Replace the content of src/components/EditReminder.js with the following code:`,
        code: ` // src/components/EditReminder.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../utils/auth';
import { motion } from 'framer-motion';

function EditReminder() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [reminderTime, setReminderTime] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchReminder();
    // eslint-disable-next-line
  }, []);

  const fetchReminder = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/reminders', {
        headers: {
          Authorization: \`Bearer \${getToken()}\`,
        },
      });
      const reminder = response.data.reminders.find((r) => r.id === parseInt(id));
      if (reminder) {
        setTitle(reminder.title);
        setDescription(reminder.description);
        setReminderTime(reminder.reminder_time.slice(0, 16).replace(' ', 'T'));
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error fetching reminder:', error);
      navigate('/dashboard');
    }
  };

  const handleEditReminder = async (e) => {
    e.preventDefault();
    setErrorMsg('');

    // No need to adjust for timezone offset; use the reminderTime as is
    const reminderTimeFormatted = reminderTime.replace('T', ' ') + ':00';

    try {
      await axios.put(
        \`http://localhost:5000/api/reminders/\${id}\`,
        {
          title,
          description,
          reminder_time: reminderTimeFormatted,
        },
        {
          headers: {
            Authorization: \`Bearer \${getToken()}\`,
          },
        }
      );
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating reminder:', error);
      setErrorMsg('An error occurred. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-red-500 via-pink-500 to-purple-500 flex items-center justify-center">
      <motion.div
        className="p-8 bg-white rounded-xl shadow-xl w-full max-w-md"
        initial={{ rotateY: 90, opacity: 0 }}
        animate={{ rotateY: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Edit Reminder</h2>
        {errorMsg && (
          <motion.p
            className="text-red-500 mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {errorMsg}
          </motion.p>
        )}
        <form onSubmit={handleEditReminder}>
          <div className="mb-4">
            <label className="block text-gray-700">Title:</label>
            <motion.input
              type="text"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-red-500"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              placeholder="Enter reminder title"
              whileFocus={{ scale: 1.02 }}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Description:</label>
            <motion.textarea
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-red-500"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter reminder description"
              whileFocus={{ scale: 1.02 }}
            ></motion.textarea>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700">Reminder Time:</label>
            <motion.input
              type="datetime-local"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-red-500"
              value={reminderTime}
              onChange={(e) => setReminderTime(e.target.value)}
              required
              whileFocus={{ scale: 1.02 }}
            />
          </div>
          <motion.button
            type="submit"
            className="w-full bg-red-600 text-white py-2 rounded-full font-semibold shadow-md"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Update Reminder
          </motion.button>
        </form>
      </motion.div>
    </div>
  );
}

export default EditReminder;
`,
       
    },
    {
        title: "Step 7.1: Testing the Updated Pages: ",
        description: `Start the Frontend Server`,
        code: `npm start`,
        youtubeTime: "54m55s",
    },
    {
        title: "Step 7.2: Navigate Through Each Page",
        description: `Register Page: http://localhost:3000/register
Login Page: http://localhost:3000/login
Dashboard: http://localhost:3000/dashboard (after logging in)
Add Reminder: Click on "Add Reminder" from the dashboard.
Edit Reminder: Click on "Edit" next to a reminder in the dashboard.
Expected Behavior:
Animations: Each page should have smooth animations when elements appear.
Styling: Enhanced visuals with gradients, rounded corners, and shadows.
Interactivity: Buttons and input fields should have hover and focus effects.
Consistency: The design should feel cohesive across all pages.`,
        
       
    },
    {
        title: "Congratulation",
        description: `You just created a fully functional "NotifyMe" app just by copy-pasting code.`,
        
    },
  
  ];
  